.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.App-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
 
}

@media (min-width: 650px ) {
  .App {
      background-position: center center;
  }

  .App-content {
      align-items: center;
      justify-content: center;
  }
}